import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const BeteiligungAnDiskursen = ({ data }) => (
    <InnerPage
        title="Beteiligung an Diskursen"
        description="Bemühung zum gesellschaftlichen Fortschritt beizutragen"
    >
        <IntroHolder
            title="Beteiligung an Diskursen"
            subtitle="Bemühung zum gesellschaftlichen Fortschritt beizutragen"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Gemeinsames Handeln',
                'current-item': 'Beteiligung an Diskursen',
            }}
        >
            <IntroQuote>
                Mit solchen Gedanken im Sinn beginnen Bahá’í … eine
                Zusammenarbeit mit einer wachsenden Zahl von Bewegungen,
                Organisationen, Gruppen und Einzelnen […] die sich um
                gesellschaftlichen Wandel bemühen, die Sache der Einheit
                vorantragen, sich für menschliche Wohlfahrt einsetzen und zu
                weltweiter Solidarität beitragen.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    In Deutschland finden auf unterschiedlichen Ebenen Diskurse
                    über unterschiedliche Belange der Gesellschaft statt, sei es
                    auf nachbarschaftlicher, kommunaler, Landes- oder
                    Bundesebene.
                </p>
                <div>
                    <p>
                        Themen wie Bildung, Erziehung, Frauen, soziale
                        Gerechtigkeit oder Integration sind nur einige
                        Beispiele. Bahá’í versuchen als{' '}
                        <Link to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/der-einzelne/">
                            Einzelne
                        </Link>
                        , in ihrem Beruf, Studium oder in ehrenamtlichem
                        Engagement an konstruktiven, fortlaufenden Gesprächen
                        teilzunehmen und dabei von anderen zu lernen sowie
                        Gedanken aus den Lehren der Bahá’í-Religion
                        einzubringen.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Auf bundesweiter Ebene ist die Bahá’í-Gemeinde in
                        Deutschland bestrebt, geeignete Wege der Beteiligung an
                        Diskursen und ihrer Mitgestaltung zu erkunden. Das Büro
                        für Außenbeziehungen des Nationalen Geistigen Rates der
                        Bahá’í in Deutschland nimmt an unterschiedlichen
                        Konferenzen, Foren und Veranstaltungen teil und strebt
                        Publikationen zu bestimmten Themen an. Derzeit sind
                        Themenschwerpunkte ‘Migration und Integration’ und die ‘
                        <Link to="/gemeinsames-handeln/beteiligung-an-diskursen/religion-in-der-gesellschaft/">
                            Rolle von Religion in der Gesellschaft
                        </Link>
                        ’.
                    </p>
                    <p>
                        Bahá’í sehen ihre Rolle dabei in erster Linie darin, auf
                        konzeptioneller Ebene zu den Diskursen beizutragen. Sie
                        berufen sich dabei insbesondere auf die{' '}
                        <Link to="/woran-bahai-glauben/natur-des-menschen/">
                            geistig-spirituell Ausrichtung des Menschen
                        </Link>{' '}
                        und auf Erfahrungen, die im kleineren Maßstab in der
                        Bahá’í-Gemeinde gewonnen werden.
                    </p>
                </div>
                <Reference>
                    <p>1. Das Universale Haus der Gerechtigkeit</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default BeteiligungAnDiskursen;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: {
                eq: "beteiligung-an-gesellschaftlichen-diskursen-feature-1.jpg"
            }
        ) {
            ...fluidImage
        }
        collage: file(
            relativePath: {
                eq: "beteiligung-an-gesellschaftlichen-diskursen-img-1.jpg"
            }
        ) {
            ...fluidImage
        }
    }
`;
